import React, { useEffect, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";

import { POST } from "../../../services/apiServices";
import Spinner from "../../../shared/components/Spinner";
import { STORAGE } from "../../../shared/methods/methods";
import { VERSION } from "../../../Config";
import { Toast } from "../../../shared/components";
import PasswordChange from "./PasswordChange";
import OTPComponent from "./OTPComponent";
import { handleCatch } from "../../../shared/methods/apiMethods";
import { authSet, userInfo } from "../../../redux/actions/auth";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    background: "#2196f314",
  },
  container: {
    padding: "20px",
    width: "400px",
    margin: "0 auto",
    maxWidth: "100%",
  },
  formControl: {
    width: "100%",
  },

  image: {
    backgroundImage: "url(./images/Artwork.svg)",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#DEEBFF",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: "0.9",
    backgroundSize: "80%",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: "10px 8px",
    background: "#00458b",
    "&:hover": {
      background: "#00458b",
    },
  },
  logoImg: {
    width: "119px",
    textAlign: "center",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "50%",
    padding: "3px",
    height: "114px",
    "& img": {
      maxWidth: "70%",
      margin: "13px",
    },
  },
  root: {
    height: "100vh",
    background: "#DEEBFF",
    position: "relative",
  },
  bgColor: {
    background: "#DEEBFF",
  },
}));

export default function InsurerSignIn(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [login, setLogin] = useState({ userName: null, password: null, });
  const [touched, setTouched] = useState({ userName: null, password: null });
  const [state, setState] = useReducer(
    (a, b) => ({ ...a, ...b }), {
    errorMsg: '',
    submitting: false,
    errorClass: '',
    renderScreen: 1,
    loginMessage: ''
  }
  )

  useEffect(() => {
    localStorage.clear();
    document.title = "Insurer Login"
  }, []);

  const handleChange = (event) => {
    if (event && event.persist) {
      event.persist();
    }
    setLogin((Checking) => ({
      ...Checking,
      [event.target.name]: event.target.value,
    }));
    setTouched((Checking) => ({
      ...Checking,
      [event.target.name]: event.target.value,
    }));
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const hasError = (field) => {
    let error = "";
    if (!login[field]) {
      error = "required";
    }
    return error;
  };

  const validate = () => {
    if (!login["userName"] || !login["password"]) {
      setState({ errorMsg: '', errorClass: 'Login-error-msg' })
      return false;
    }
    setState({ errorMsg: '', errorClass: 'Login-error-msg' })
    return true;
  };


  const handleSubmit = (event) => {
    if (state.submitting) {
      return
    }
    STORAGE.deleteAuth()
    if (event && event.persist) {
      event.persist();
    }
    setTouched({
      userName: true,
      password: true,
    });
    if (validate()) {
      setState({ submitting: true })
      const payload = {
        LoginID: login.userName,
        Password: login.password,
      };
      POST('WeCare/LoginForWecare', payload, 'weCare')
        // POST('LoginForWecare', payload, '')
        .then((res) => {
          setState({ submitting: false })
          let { ErrorCode, Data } = res;
          if (ErrorCode === 0) {
            if ([2, 3].includes(Data?.LoginStatus)) {
              setState({ renderScreen: Data.LoginStatus, loginMessage: Data.LoginMessage })
              STORAGE.setAuthToken(Data?.Token || "")
            }
            else if (Data?.LoginStatus === 1) {
              STORAGE.setAuthToken(Data.Token || "").then(() => {
                dispatch(userInfo(Data)) // sets the Logged User data in Redux Store
                dispatch(authSet(true));
                Toast.success(`Welcome back! ${Data.Name}`)
                props.history.push("/InsurerDashboard"); // navigates the user to the mentioned url
              })
            }
          }
          else {
            setState({ errorMsg: res.Message || 'Some error occurred', errorClass: 'Login-error-msg' })
          }
        })
        .catch((err) => {
          setState({ submitting: false })
          handleCatch(err)
        });
    }
  };

  const resetOptions = () => {
    STORAGE.deleteAuth();
    setState({
      errorMsg: '',
      submitting: false,
      errorClass: '',
      renderScreen: 1,
      loginMessage: ''
    })
    setLogin({ userName: null, password: null, })
    setTouched({ userName: null, password: null, })
  }

  const screen1 = () => {
    return <div className="login-box">
      <h2>Login</h2>
      <p className="sub-heading">Manage bookings, medicals & documents</p>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <FormControl className="form-control form-control-login ">
            <TextField
              className={classes.margin}
              id="signIn-username"
              name="userName"
              label="User Name"
              InputLabelProps={{
                shrink: true,
              }}
              value={login?.userName}
              onChange={handleChange}
              onKeyPress={handleKeypress}
              error={hasError("userName") && touched["userName"]}
              helperText={
                hasError("userName") && touched["userName"]
                  ? hasError("userName")
                  : null
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl className="form-control form-control-login ">
            <TextField
              className={classes.margin}
              id="signIn-password"
              label="Password"
              type="password"
              name="password"
              InputLabelProps={{
                shrink: true,
              }}
              value={login?.password}
              onChange={handleChange}
              onKeyPress={handleKeypress}
              error={hasError("password") && touched["password"]}
              helperText={
                hasError("password") && touched["password"]
                  ? hasError("password")
                  : null
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12} className="text-center">
          <Button
            className="login-btn"
            type="submit"
            onClick={handleSubmit}
            id='signIn-signIn-btn'
          >
            Sign in <span className="loader-icon">{state.submitting ? <Spinner /> : ""}</span>
          </Button>
          {/* } */}
          <p className={state.errorClass}>{state.errorMsg}</p>
        </Grid>
        <Grid item md={12} xs={12} className="text-center">
          Version : {VERSION}
        </Grid>
      </Grid>
    </div>
  }

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={5} md={8} className={classes.image} />
        <Grid item xs={12} sm={7} md={4} elevation={6} square className={classes.bgColor}
        >
          {state.renderScreen === 1 && screen1()}
          {state.renderScreen === 2 && <PasswordChange resetOptions={resetOptions} />}
          {state.renderScreen === 3 && <OTPComponent loginMessage={state.loginMessage} resetOptions={resetOptions} history={props.history} />}
        </Grid>
      </Grid>
    </div>
  );
}